<template>
  <v-app>
    <Navigation v-if="$cookies.isKey('user')" />
    <v-main>
      <v-card id="outer" flat :loading="$cookies.isKey('loading')">
        <router-view />
      </v-card>
    </v-main>
    <Footer v-if="$cookies.isKey('user')" />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
#app {
  background-color: aliceblue;
}
#outer {
  background-color: aliceblue;
  height: 100%;
}
</style>
