import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import cookies from "vue-cookies-reactive";
import moment from "vue-moment";

Vue.config.productionTip = false;
Vue.use(cookies);
Vue.use(moment);
Vue.component("pdf-viewer", import("@/components/PdfViewer.vue"));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
